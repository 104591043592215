import {useModal, useProfile} from "../../context";
import Login from "./../modals/Login";
import React from "react";
import PreCheck from "../modals/PreCheck";
import Reg from "../modals/Reg";

function CodesWidget({extraClasses = null, closeNav = null}) {
    const {profile} =  useProfile();
    const { show, hide} = useModal();

    return (
        <>
            <div onClick={()=>{

                if(closeNav){
                    closeNav();
                }
                if(profile.loggedIn && profile.data.profile.userUuid) {
                    if(window.promoTools) {
                        window.promoTools.checkGrabberWidget.settings = {
                            skin: 'custom',
                            api: process.env.REACT_APP_API_PROXY,
                            method: 'widgetsReceipts',
                            userUuid: profile.data.profile.userUuid,
                            i18n: {
                                qr: {
                                    title: 'Регистрация чека',
                                    subTitle: 'Наведите камеру на QR код в чеке'
                                },
                                manual: {
                                    title: 'Ввести данные <br/><span>с чека вручную</span>',
                                    subTitle: '',
                                },
                                photos: {
                                    title: 'Загрузка <span>чека</span>',
                                    subTitle: 'Добавьте фото чека',
                                    btnPhotos: 'Отправить',
                                },
                                pending: {
                                    title: 'Подождите!',
                                    subTitle: 'Идет отправка чека',
                                },
                                fail: {
                                    title: 'Не удалось <br/> <span>отправить чек!</span>',
                                },
                                success: {
                                    title: 'Спасибо за&nbsp;чек',
                                    subTitle: 'Ваш чек обрабатывается до&nbsp;5-ти рабочих дней. Узнать статус чека можно в&nbsp;личном кабинете.',
                                },
                            },
                            events: {
                                onSentSuccess(res) {
                                    let el = document.getElementById('update-codes');
                                    if(el){
                                        el.click();
                                    }

                                    if(window.ym){
                                        window.ym(97094979,'reachGoal', 'Успешная регистрация чека');
                                    }
                                },
                            },
                        };
                        //window.promoTools.checkGrabberWidget.show();
                    }
                    show(<PreCheck close={hide}/>, "modal--form modal--precheck");
                }else {
                    show(<Reg/>, "modal--form modal--reg");
                    //show(<Login/>, "modal--form modal--login");
                }
            }} className={'btn-ct d-flex align-items-center justify-content-center ' + extraClasses ?? ''} id="rec-trigger-show">
                <div className="btn--anim">
                    <div className="btn--anim-inner"/>
                </div>
                <span>зарегистрировать чек</span>
            </div>
        </>
    );
}

export default CodesWidget;
