import {useState} from 'react'
import {useModal} from "../../context";

function Contest(){
    const { show, hide} = useModal();

    return (
        <>
            <div className="modal__contest-box">
                <div className="modal__decor--1"/>
                <div className="modal__decor--2"/>
                <div className="modal__decor--3"/>
                <div className="modal__decor--4"/>
                <div className="modal__contest-title">
                    Участвуй в&nbsp;конкурсе
                </div>
                <div className="modal__contest-text">
                    <p>
                        Придумай фреш-кольные строчки и&nbsp;получи топовые беспроводные наушники! Переходи в&nbsp;группу Fresh Bar Cola во&nbsp;ВКонтакте. В&nbsp;конкурсном посте ты&nbsp;увидишь рэп о&nbsp;лете, кайфе и&nbsp;Fresh Bar Cola Вали Карнавал. В&nbsp;комментариях участники продолжают друг друга.
                    </p>
                    <p>
                        Выбери последний комментарий, придумай строчку и&nbsp;напиши ее&nbsp;в&nbsp;ответ. Рифмуй, рэпуй свободно, развивай историю, главное&nbsp;&mdash; делай фреш-кольно! Автор самых креативных строчек получит топовые беспроводные наушники!
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <a href="https://vk.com/wall-57886422_119154" onClick={()=>{
                        if(window.ym) {
                            window.ym(97094979,'reachGoal', 'участвовать в конкурсе');
                        }
                    }} target="_blank" className="btn-ct btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                        Участвовать
                    </a>
                </div>
            </div>
        </>
    );
}

export default Contest;
