import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ProfileProvider, ModalProvider} from "./context";
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import PageNotFound from "./pages/PageNotFound";
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title>Freshbar Cola — Это фрешкольно</title>
              <meta property="og:title" content="Freshbar Cola — Это фрешкольно"/>
          </Helmet>
          <ProfileProvider>
              <ModalProvider>
                  <div id="wrapper" className="wrapper">
                      <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/cabinet" element={<Cabinet />} />
                          <Route path="*" element={<PageNotFound />} />
                      </Routes>
                  </div>
              </ModalProvider>
          </ProfileProvider>
      </>
  );
}

export default App;
