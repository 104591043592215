import {useState} from 'react'
import { Link } from 'react-router-dom'
import {useModal} from "../../context";
import parse from "html-react-parser";

function ImgCheck({check_fd = null, check_fn = null, check_fp = null, img = [], close}){
    const { show, hide} = useModal();

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    {check_fd && (
                        <div className="modal__subtitle">
                            ФД: {check_fd}
                        </div>
                    )}
                    {check_fn && (
                        <div className="modal__subtitle">
                            ФН: {check_fn}
                        </div>
                    )}
                    {check_fp && (
                        <div className="modal__subtitle">
                            ФП: {check_fp}
                        </div>
                    )}
                </div>
                {img && img.length > 0 && (
                    <div className="modal__ct-box">
                        {img.map((imgIt, i) => (
                            <div className="modal__ct-img">
                                <img src={imgIt}/>
                            </div>
                        ))}
                    </div>
                )}

            </div>
        </>
    );
}

export default ImgCheck;
