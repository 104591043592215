import parse from 'html-react-parser';
import {useProfile} from "../../context";
import {Link, useLocation} from 'react-router-dom'

function Info({title = '', feedback = false, bad= false, text = '', hint = '', icon = false, regBtn = false, close}){
    const {profile} = useProfile();
    const location = useLocation();

    return (
        <>
            <div className="modal__ct">
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle">
                            {parse(text)}
                        </div>
                    </div>
                )}
                {hint && !feedback && (
                    <div className="modal__ct-box">
                        <div className="modal__text text-center">
                            {parse(hint)}
                        </div>
                    </div>
                )}

                {feedback && (
                    <div className="modal__ct-box">
                        {profile.loggedIn && location.pathname != '/' && (
                            <div className="modal__text text-center">
                                Ответ будет отправлен на&nbsp;указанный электронный адрес и&nbsp;продублирован в&nbsp;разделе «<a onClick={()=>{
                                    if(document.getElementById('feedback-tab')){
                                        document.getElementById('feedback-tab').click();
                                    }
                            }}>Мои вопросы</a>»
                            </div>
                        )}

                        {profile.loggedIn && location.pathname == '/' && (
                            <div className="modal__text text-center">
                                Ответ будет отправлен на&nbsp;указанный электронный адрес и&nbsp;продублирован в&nbsp;разделе «<Link onClick={()=>{
                                    close();
                            }} to="/cabinet#feedback">Мои вопросы</Link>»
                            </div>
                        )}

                        {!profile.loggedIn && (
                            <div className="modal__text text-center">
                                Ответ будет отправлен на&nbsp;указанный электронный адрес
                            </div>
                        )}
                    </div>
                )}

                {regBtn && (
                    <div className="modal__ct-box">
                        <div className="d-flex justify-content-center modal__bottom-btn">
                            <div onClick={()=>{
                                close();
                                if(document.querySelectorAll('#apm-scan-qr')) {
                                    document.querySelectorAll('#apm-scan-qr')[0].click();
                                }
                            }} className="btn-ct btn-ct--medium">
                                <span>зарегистрировать чек</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Info;
